.benefits {
    padding: 0 6% 10%;
}

.checkbox-phone {
    padding: 5% 5%;
}

.check-mark {
    font-size: x-large;
}

/* body,.container-FPF-phone{
    overflow-x: hidden ;
} */