.product-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3% 0;
}

.product-list-title > img {
    height: fit-content;
}

.selected > img {
    margin-right: 1%;
}

.product-list-title:hover {
    cursor: pointer;
}

.product-list-title + div {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;

    padding-left: 5%;
    margin-bottom: 3%;
}

.selected + div {
    max-height: 1000px; /* Ajustez la valeur en fonction de la longueur des paragraphs */
    transition: max-height 0.5s ease-in;
}

.selected + .small {
    max-height: 500px; /* Ajustez la valeur en fonction de la longueur des paragraphs */
}

.selected, .selected .text {
    color: #CE886D;
    border-color: #CE886D;
}

@media (max-width: 750px) {
    .selected + div {
        max-height: 1500px; /*paragraphs plus long en format téléphone (moins large)*/
    }

    .product-list-title > img {
        max-height: 30px;
    }

    .selected > img {
        max-height: 12px;
    }
}