
.div-footer-container {
    background-color: black;
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
}

.div-footer-menuLogo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

.p-footer-navigation {
    font-family: 'Poppins', sans-serif;
    color: #111426;
    font-size: 24px;
}

.p-footer-menu {
    font-family: 'Poppins', sans-serif;
    color: #6D6869;
    font-size: 16px;
    cursor: pointer;
}

.div-footer-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.img-footer-logo {
    height: 100px;
}

.p-footer-logo {
    font-family: 'Poppins', sans-serif;
    color: #111426;
    font-size: 30px;
    margin: 0;
}

.p-footer-legal {
    font-size: 16px;
    color: #ADB5BD;
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    width: 100%;
    text-align: center;
}

@media (max-width: 600px) {
.div-footer-menuLogo,.div-footer-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}

/* @media (max-width: 768px) {
    .div-footer-container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .div-footer-menuLogo {
        flex-direction: column-reverse;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-footer-logo {
        font-size: 20px;
    }

    .img-footer-logo {
        height: 35px;
    }

    .p-footer-legal {
        margin: 0;
    }

    .div-footer-container {
        padding-top: 0
    }
} */
