.text {
    font-family: 'poppins', 'sans-serif';
    color: #474748;
    margin: 0;
}

.title {
    text-transform: uppercase;
}

.nowrap{
    white-space: nowrap;
}

.brown-text {
    color: #CE886D;
}

.underligned {
    border-bottom: 1px solid #ADB5BD;
}

.logo-and-image {
    display: flex;
    align-items: center;
    margin: 2% 0;
}

.logo-and-image img {
    max-height: 40px;
    margin-right: 1%;
}

.pargraph {
    margin-top: 2%;
}

/* checkboxes */
.checkbox-square {
    background: #F9F9F9;
    max-width: 550px;
    border-radius: 3%;
    margin-top: 4%;
    padding: 2% 2%;
    width: 90%;
}

.check-line {
    display: flex;
    align-items: start;
}

.check-mark {
    font-size: xx-large;
    margin: 0;
}