.container-one-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10% 0;
    max-width: 300px; /* Ajoutez une largeur maximale pour les cartes de produits */
}

@media (min-width: 442px) {
    .products-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 20px; /* Ajoute un espacement entre les cartes */
    }
}


/* two next sections are used for the image slide effet on hover*/
.container-one-product img {
    position: relative;
    top: 0;
    transition: top ease 0.5s;
}

.container-one-product:hover img{
    cursor: pointer;
    top: -20px;
}

.container-one-product:hover{
    cursor: pointer;
}

.img-product-pres {
    max-width: 100%;
    min-width: 150px;
    height: auto;
    object-fit: cover;
}

.small img{
    min-width: 100px;
}

.text-product-product {
    font-family: 'poppins';
    text-align: center;
    font-size: 25px;
    text-transform: uppercase;
    color: #1B1C3C;
    margin: 0;
    margin-top: 20px;
}

.product-shortdesc {
    font-family: 'poppins';
    font-size: 13px;
    text-align: center;
    color: #6D6869;
    margin: 0;
}

/* version small */
.small {
    margin: 3% 0;
}

.small .img-product-pres {
    height: 220px;
    margin-bottom: 8%;
}

.small .text-product-product{
    width: min-content;
}

/* version telephone */
@media (max-width: 441px) {
    .img-product-pres {
        height: auto;
    }

    .small .text-product-product {
        font-size: 20px;
    }
}